import {
    SET_INIT,
    INITIAL_AMOUNT,
    INCREASE_AMOUNT,
    DECREASE_AMOUNT,
    GET_USER_DATA,
    SET_USERNAME,
    SET_IN_PLAY_COUNT,
    ADD_BULK_FAVORITE_MATCH,
    ADD_FAVORITE_MATCH,
    DELETE_FAVORITE_MATCH,
    TOGGLE_LOCK_BET,
    ADD_BET,
    SET_BET,
    UPDATE_BET,
    DELETE_BET,
    DELETE_ALL_BET,
    DELETE_ALL_BET_EXCEPT,
    SET_BET_TYPE,
    SET_MODAL,
    SET_VARS,
    SET_VERIFICATION,
    DELETE_VERIFICATION,
    SET_LANGUAGE,
    SET_STREAM,
} from "../constants/action-types";

const initialState = {
    init: false,
    userData: {},
    homeData: null,
    amount: 0,
    favoriteMatch: [],
    verification: {},
    bet: {
        lock: false,
        items: [],
        singlesInput: 0,
        sumAllPrice: 0,
        sumSinglePrice: 0,
        awardSinglePrice: 0,
        mix: false,
        type: 1,//1 => Single, 2 => Multiple
        suspend: true,
        mixPrice: 0,
        mixOdd: 0,
        live: false,
        awardMixPrice: 0,
        sumAwardPrice: 0,
    },
    vars: {
        user: {},
        inPlayCount: 0,
        markets: {},
        counties: {},
        title: null,
        description: null,
        logo: null,
        favicon: null,
        min: 0,
        mix: 0,
        max: {
            inPlay: 0,
            preMatch: 0,
        },
        signUp: null,
        verify: null,
        supportCurrencies: null,
        supportLanguage: [],
        defaultLanguage: 'en',
        socialNetworks: null,
        currency: {
            symbol: 'تومان',
            label: 'تومان',
            fractionDigits: 0,
            rate: 10,
        },
    },
    modal: 0,
    stream: null,
    language: 'en-US',
};

function rootReducer(state = initialState, action) {
    let live = false;
    switch (action.type) {
        case SET_INIT:
            return Object.assign({}, state, {
                init: action.payload,
            });
        case GET_USER_DATA:
            return Object.assign({}, state, {
                userData: action.payload,
            });
        case SET_USERNAME:
            return Object.assign({}, state, {
                userData: {
                    name: action.payload,
                }
            });
        case SET_IN_PLAY_COUNT:
            return {...state,
                vars: {
                    ...state.vars,
                    inPlayCount: action.payload,
                },
            };
        case SET_VARS:
            return Object.assign({}, state, {
                vars: action.payload,
            });
        case SET_VERIFICATION:
            return { ...state, verification: action.payload };
        case DELETE_VERIFICATION:
            return {
                ...state,
                verification: state.verification.map((item, index) => {
                    return Object.assign({}, state.verification[index], {
                        value: (item.id === action.payload.payment ? state.verification[index].value.filter((verify, ) => verify !== action.payload.verify.toString()) : state.verification[index].value),
                    });
                }),
            };
        case INITIAL_AMOUNT:
            return Object.assign({}, state, {
                amount: action.payload,
            });
        case INCREASE_AMOUNT:
            return Object.assign({}, state, {
                amount: state.amount + action.payload,
            });
        case DECREASE_AMOUNT:
            return Object.assign({}, state, {
                amount: state.amount - action.payload,
            });
        case ADD_BULK_FAVORITE_MATCH:
            return {...state, favoriteMatch: [...state.favoriteMatch, ...action.payload]};
        case ADD_FAVORITE_MATCH:
            return {...state, favoriteMatch: [...state.favoriteMatch, action.payload]};
        case DELETE_FAVORITE_MATCH:
            return {
                ...state,
                favoriteMatch: state.favoriteMatch.filter((item, index) => item.id !== action.payload),
            };
        case TOGGLE_LOCK_BET:
            return {...state,
                bet: {
                    ...state.bet,
                    lock: !state.bet.lock,
                },
            };
        case ADD_BET:
            return {...state,
                bet: {
                    ...state.bet,
                    items: [
                        ...state.bet.items,
                        action.payload.item,//[action.payload.item.id]: action.payload.item,
                    ],
                    mix: action.payload.mix,
                    mixOdd: action.payload.mixOdd,
                    type: action.payload.mix !== true ? 1 : state.bet.type,
                    suspend: action.payload.suspend,
                },
                //vars: action.payload.vars,
            };
        case SET_BET:
            return {...state,
                bet: {
                    ...state.bet,
                    items: state.bet.items.map((item, index) => {
                        return Object.assign({}, state.bet.items[index], {
                            price: Object.keys(action.payload.items).indexOf(item.id.toString()) > -1 ? action.payload.items[item.id].price : state.bet.items[index].price,
                        });
                    }),
                    mixPrice: action.payload.mixPrice,
                    sumSinglePrice: action.payload.sumSinglePrice,
                    awardSinglePrice: action.payload.awardSinglePrice,
                    awardMixPrice: action.payload.awardMixPrice,
                    sumAllPrice:  action.payload.mix && state.bet.type === 2 ? (action.payload.mixPrice !== '' ? action.payload.mixPrice : 0) : (action.payload.sumSinglePrice !== '' ? action.payload.sumSinglePrice : 0),
                    sumAwardPrice: action.payload.mix && state.bet.type === 2 ? action.payload.awardMixPrice : action.payload.awardSinglePrice,
                },
            };
        case UPDATE_BET:
            return {...state,
                bet: {
                    ...state.bet,
                    items: state.bet.items.map((item, index) => {
                        let existItem = Object.keys(action.payload.items).indexOf(item.id.toString()) > -1;
                        let odd = existItem ? action.payload.items[item.id] : state.bet.items[index].odd;
                        item.live = odd.inPlay === 1;
                        if (!live) {
                            live = item.live;
                        }
                        delete odd.inPlay;

                        return {
                            ...item,
                            odd: {
                                ...odd,
                                label: state.bet.items[index].odd.label,
                            },
                            awardPrice: existItem ? (state.bet.items[index].price * action.payload.items[item.id].value) : state.bet.items[index].awardPrice,
                        };
                        /*return Object.assign({}, state.bet.items[index], {
                            odd: {
                                ...odd,
                                value: value
                            },
                            awardPrice: existItem ? (state.bet.items[index].price * action.payload.items[item.id].value) : state.bet.items[index].awardPrice,
                        });*/
                    }),
                    mixOdd: action.payload.mix && state.bet.type === 2 ? action.payload.mixOdd : state.bet.mixOdd,
                    suspend: action.payload.suspend,
                    live: live,
                    awardSinglePrice: action.payload.awardSinglePrice,
                    awardMixPrice: action.payload.awardMixPrice,
                    sumAwardPrice: action.payload.mix && state.bet.type === 2 ? action.payload.awardMixPrice : action.payload.awardSinglePrice,
                },
            };
            /*return {...state,
                bet: {
                    ...state.bet,
                    items: state.bet.items.map((item, index) => {
                        return Object.keys(action.payload.items).indexOf(item.id.toString()) > -1 ?
                            action.payload.items[item.id].odd : state.bet.items[index];
                        }),
                },
            };*/
        case DELETE_BET:
            return {
                ...state,
                bet: {
                    ...state.bet,
                    items: state.bet.items.filter((item, index) => item.id !== action.payload.id),
                    singlesInput: state.bet.items.length === 1 ? '' : state.bet.singlesInput,
                    suspend: action.payload.suspend,
                    mix: action.payload.mix,
                    type: action.payload.mix !== true ? 1 : state.bet.type,
                    mixPrice: action.payload.mixPrice,
                    mixOdd: state.bet.items.length === 2 ? 0 : state.bet.mixOdd,
                    sumSinglePrice: action.payload.sumSinglePrice,
                    awardSinglePrice: action.payload.awardSinglePrice,
                    awardMixPrice: action.payload.awardMixPrice,
                    sumAllPrice: action.payload.mix && state.bet.type === 2 ? action.payload.mixPrice : action.payload.sumSinglePrice,
                    sumAwardPrice: action.payload.mix && state.bet.type === 2 ? action.payload.awardMixPrice : action.payload.awardSinglePrice,
                },
            };
        case DELETE_ALL_BET:
            return {...state,
                bet: {
                    lock: false,
                    items: [],
                    singlesInput: 0,
                    sumSinglePrice: 0,
                    awardSinglePrice: 0,
                    mix: false,
                    type: 1,
                    suspend: true,
                    mixPrice: '',
                    mixOdd: 0,
                    awardMixPrice: 0,
                    sumAllPrice: 0,
                    sumAwardPrice: 0,
                },
            };
        case DELETE_ALL_BET_EXCEPT:
            return {...state,
                bet: {
                    ...state.bet,
                    items: state.bet.items.filter(item => {
                        return action.payload.includes(item.id.toString());
                    }),
                    lock: false,
                },
            };
        case SET_BET_TYPE:
            return {
                ...state,
                bet: {
                    ...state.bet,
                    items: state.bet.items.map((item, index) => {
                        return Object.assign({}, state.bet.items[index], {
                            price: 0,
                        });
                    }),
                    type: action.payload,
                    singlesInput: 0,
                    mixPrice: 0,
                    sumSinglePrice: 0,
                    awardSinglePrice: 0,
                    awardMixPrice: 0,
                    sumAllPrice: 0,
                    sumAwardPrice: 0,
                },
            };
        case SET_MODAL:
            return Object.assign({}, state, {
                modal: action.payload,
            });
        case SET_LANGUAGE:
            return Object.assign({}, state, {
                language: action.payload,
            });
        case SET_STREAM:
            return Object.assign({}, state, {
                stream: action.payload,
            });
        default:
            return state;
    }
}

export default rootReducer;
