import axios from 'axios';
import Storage from './storage';
import {toast} from "react-toastify";
import Helper from "../components/General/Helper";
import i18next from "i18next";

let lastShowToast = 0;
let Api = axios.create({
    baseURL: Helper.API_URL(),
    responseType: 'json',
});
Api.CancelToken = axios.CancelToken;
Api.isCancel = axios.isCancel;

Api.interceptors.request.use(function (config) {
    if (i18next.language !== undefined) {
        config.headers['Accept-Language'] = i18next.language;
    }
    config.headers['Accept'] = 'application/json; Charset=utf8';
    config.headers['Content-Type'] = 'application/json; Charset=utf8';
    config.headers['X-Client-Id'] = process.env.REACT_APP_CLIENT_ID;
    config.headers['Authorization'] = Storage.getToken() ? 'Bearer ' + Storage.getToken() : null;

    return config;
}, function (error) {
    return Promise.reject(error);
});

Api.interceptors.response.use(function (response) {
    if (parseInt(response.headers['x-sp']) === 1) {
        logOut();
    }
    return response;
}, function (error) {
    if (error.response) {
        if ([403, 404, 425, 429, 500].includes(error.response.status)) {
            toast.error(error.response.data.message);
        } else if (error.response.status === 401) {
            if (error.config.url.includes('auth/token')) { //means refresh token has expire
                logOut();
            } else {
                const refreshToken = Storage.getRefreshToken();
                if (!refreshToken) {
                    logOut();
                }

                return Api({
                    method: 'post',
                    url: 'auth/token',
                    data: {
                        grant_type: 'refresh_token',
                        client_id: process.env.REACT_APP_CLIENT_ID,
                        client_secret: process.env.REACT_APP_CLIENT_SECRET,
                        refresh_token: Storage.getRefreshToken(),
                    },
                }).then((response) => {
                    // 1) put token to LocalStorage
                    Storage.saveToken(response.data.data);

                    // 2) Change Authorization header
                    Api.defaults.headers.common['Authorization'] = `Bearer ${response.data.data}`;

                    // 3) return originalRequest object with Axios.
                    return Api({
                        method: error.config.method,
                        url: error.config.url,
                        data: error.config.data,
                        params: error.config.params
                    });
                }).catch(err => {
                    logOut();
                });
            }
        } else if (error.response.status === 503) {
            if (error.response.data.name === 'Maintenance') {
                window.location = '/maintenance';
            } else if (error.response.data.name === 'Updating') {
                toast.info(error.response.data.message);
            }
        }
    } else if (error.request) {
        // The request was made but no response was received
        let now = Math.round(+new Date() / 1000);
        if (lastShowToast < now) {
            lastShowToast = now + 10;
            toast.error('در این لحظه ارتباط با سرور قطع شده است.');
        }
    } else {
        // Something happened in setting up the request that triggered an Error
        // The request was made but no response was received
        let now = Math.round(+new Date() / 1000);
        if (lastShowToast < now) {
            lastShowToast = now + 10;
            toast.error('امکان ارتباط با سرور وجود ندارد.');
        }
    }

    return Promise.reject(error);
});

const logOut = () => {
    Storage.removeRefreshToken();
    Storage.removeToken();
    window.location = '/login';
    return false;
};

export default Api;
