import React from 'react';
import "../../assets/styles/Loading.css";

const Loading = (props) => {
    return (
		<div className="loading" style={{marginTop: props.margin, marginBottom: props.margin, height: props.size}}>
		  <div className="ldio-abc">
			<div style={{width: props.size, height: props.size}}/>
			<div style={{width: props.size, height: props.size}}/>
			<div style={{width: props.size, height: props.size}}/>
		  </div>
		</div>
	);
};

Loading.defaultProps = {
  	margin: 25,
	size: 54.92,
    container: true,
    box: true,
};

export default Loading;