const TOKEN_KEY = 'access_token';
const REFRESH_TOKEN_KEY = 'refresh_token';
const USER_DATA_KEY = 'user_data_token';
const MATCH_BOOKMARK = 'match_bookmark';
const MARKET_BOOKMARK = 'market_bookmark';
const LANGUAGE = 'i18nextLng';

/**
 * Manage the how Access Tokens are being stored and retreived from storage.
 *
 * Current implementation stores to localStorage. Local Storage should always be
 * accessed through this instace.
 **/

const Storage = {
    setData(key, value) {
        localStorage.setItem(key, value);
    },
    getData(key) {
        return localStorage.getItem(key);
    },
    removeData(key) {
        localStorage.removeItem(TOKEN_KEY);
    },
    getToken() {
        return localStorage.getItem(TOKEN_KEY);
    },
    saveToken(accessToken) {
        localStorage.setItem(TOKEN_KEY, accessToken);
    },
    removeToken() {
        localStorage.removeItem(TOKEN_KEY);
    },
    getRefreshToken() {
        return localStorage.getItem(REFRESH_TOKEN_KEY);
    },
    saveRefreshToken(refreshToken) {
        localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
    },
    removeRefreshToken() {
        localStorage.removeItem(REFRESH_TOKEN_KEY);
    },
    saveUserData(name, amount) {
        let userData = Storage.getUserData();

        if (userData === null) {
            userData = {};
        }

        if (name != null) {
            userData.name = name;
        }

        if (amount != null) {
            userData.amount = amount;
        }

        localStorage.setItem(USER_DATA_KEY, JSON.stringify(userData));
    },
    getUserData() {
        let userData = localStorage.getItem(USER_DATA_KEY);

        if (userData != null && userData !== undefined) {
            userData = JSON.parse(userData);

            return {
                name: userData.name,
                amount: userData.amount,
            };
        }

        return null;
    },
    removeUserData() {
        localStorage.removeItem(USER_DATA_KEY);
        //localStorage.removeItem(AMOUNT_KEY);
    },
    getMatchBookmarkCount() {
        return Storage.getMatchBookmark().length;
    },
    getMatchBookmark() {
        try {
            return JSON.parse(localStorage.getItem(MATCH_BOOKMARK));
        } catch (e) {
            console.log(e.message);
            return false;
        }
    },
    saveMatchBookmark(matchId) {
        let matches = Storage.getMatchBookmark();
        if (matches !== false) {
            if (matches === null) {
                matches = [];
                matches.push(matchId);
            } else {
                const index = matches.indexOf(matchId);
                if (index > -1) {
                    matches.splice(index, 1);
                } else {
                    let size = 10;
                    size--;
                    const arrLength = matches.length;
                    if (arrLength > size) {
                        matches.splice(0, arrLength - size);
                    }
                    matches.push(matchId);
                }
            }
            localStorage.setItem(MATCH_BOOKMARK, JSON.stringify(matches));
        }
        return matches;
    },
    getMarketBookmark() {
        try {
            return JSON.parse(localStorage.getItem(MARKET_BOOKMARK));
        } catch (e) {
            console.log(e.message);
            return false;
        }
    },
    saveMarketBookmark(bookmarkId) {
        let markets = Storage.getMarketBookmark();
        if (markets !== false) {
            if (markets === null) {
                markets = [];
                markets.push(bookmarkId);
            } else {
                const index = markets.indexOf(bookmarkId);
                if (index > -1) {
                    markets.splice(index, 1);
                } else {
                    markets.push(bookmarkId);
                }
            }
            localStorage.setItem(MARKET_BOOKMARK, JSON.stringify(markets));
        }
        return markets;
    },
    getLanguage() {
        return localStorage.getItem(LANGUAGE);
    },
    removeAll() {
        //Storage.removeToken();
        //Storage.removeRefreshToken();
        //Storage.removeExpireToken();
        //Storage.removeUserData();
        localStorage.clear();
    },
    isLogin() {
        return (localStorage.getItem(TOKEN_KEY) && localStorage.getItem(REFRESH_TOKEN_KEY));
    }
};

export default Storage
