import React, {Suspense, lazy} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Loading from "./components/General/Loading";

const DefaultLayout = lazy(() => import('./layouts/Default'));
const GameLayout = lazy(() => import('./layouts/Game'));
const LoginLayout = lazy(() => import('./layouts/Login'));
const BetBoxLayout = lazy(() => import('./layouts/BetBox'));
const BetBox2Layout = lazy(() => import('./layouts/BetBox2'));
const EventLayout = lazy(() => import('./layouts/Event'));
const Home = lazy(() => import('./components/Home/Home'));
const Register = lazy(() => import('./components/Register/Register'));
const Activation = lazy(() => import('./components/Register/Activation'));
const Login = lazy(() => import('./components/Login/Login'));
const Deposit = lazy(() => import('./components/Payment/Deposit/Deposit'));
const SuccessfulPM = lazy(() => import('./components/Payment/Deposit/PerfectSCI/SuccessfulPM'));
const UnsuccessfulPM = lazy(() => import('./components/Payment/Deposit/PerfectSCI/UnsuccessfulPM'));
const Transaction = lazy(() => import('./components/Transaction/Transaction'));
const TicketCreate = lazy(() => import('./components/Ticket/Create'));
const TicketView = lazy(() => import('./components/Ticket/View'));
const Ticket = lazy(() => import('./components/Ticket/Index'));
const ForgetPassword = lazy(() => import('./components/ForgetPassword/ForgetPassword'));
const ResetPassword = lazy(() => import('./components/ForgetPassword/ResetPassword'));
const Profile = lazy(() => import('./components/Profile/Profile'));
const Withdrawal = lazy(() => import('./components/Payment/Withdraw/Withdrawal'));
const Help = lazy(() => import('./components/Help/Help'));
const NotFound = lazy(() => import('./components/NotFound/NotFound'));
const Contact = lazy(() => import('./components/Contact/Contact'));
const Event = lazy(() => import('./components/Event/Event'));
const History = lazy(() => import('./components/History/History'));
const InPlay = lazy(() => import('./components/Match/InPlay'));
const PreMatch = lazy(() => import('./components/Match/PreMatch'));
const Calendar = lazy(() => import('./components/Match/Calendar'));
const Game = lazy(() => import('./components/Game/Index'));
const GameView = lazy(() => import('./components/Game/View'));
const Maintenance = lazy(() => import('./components/General/Maintenance'));
const LuckyWheelView = lazy(() => import('./components/LuckyWheel/View'));
//const GameTest = lazy(() => import('./components/Game/Test'));
//const GameViewTest = lazy(() => import('./components/Game/ViewTest'));
//const VirtualSports = lazy(() => import('./components/VirtualSports/VirtualSports'));

/*
* <Link to="/public">Public Page</Link>
* */
/*<Route path="/:id" component={Child} />
{
           It's possible to use regular expressions to control what param values should be matched.
              * "/order/asc"  - matched
              * "/order/desc" - matched
              * "/order/foo"  - not matched
        }
<Route
    path="/order/:direction(asc|desc)"
    component={ComponentWithRegex}
/>*/
/*function requireAuth(nextState, replaceState) {
    if (AuthStore.getState().accessToken === null) {
        replaceState({ nextPathname: nextState.location.pathname }, '/login')
    }
}
<IndexRoute component={Dashboard} onEnter={requireAuth} />
<BrowserRouter basename="/build">
*/
/*
*
<GameLayout path="/gameTest/:provider/:name/:id/:type" component={GameViewTest} Private={true}/>
<GameLayout path="/gameTest" component={GameTest}/>
*/
const Routes = () => (
    <BrowserRouter basename="/">
        <Suspense fallback={<Loading box={false} />}>
            <Switch>
                <BetBoxLayout exact path="/" component={Home} />
                <BetBox2Layout path="/inPlay" component={InPlay}/>
                <BetBox2Layout path="/preMatch/:sportId(\d+)?/:regionId(\d+)?/:competitionId(\d+)?" component={PreMatch}/>
                <BetBox2Layout path="/calendar" component={Calendar}/>
                <EventLayout path="/event/:id(\d+)" component={Event}/>
                <LoginLayout path="/register" component={Register} Private={false}/>{/* Private: false uses for components that public but logged in user must not to access that component */}
                <LoginLayout path="/login" component={Login} Private={false}/>
                <DefaultLayout path="/deposit/successfulPM" component={SuccessfulPM}/>
                <DefaultLayout path="/deposit/unsuccessfulPM" component={UnsuccessfulPM}/>
                <DefaultLayout path="/deposit" component={Deposit} Private={true}/>
                <DefaultLayout path="/transaction" component={Transaction} Private={true}/>
                <DefaultLayout path="/ticket/create" component={TicketCreate} Private={true}/>
                <DefaultLayout path="/ticket/:code(\d+)" component={TicketView} Private={true}/>
                <DefaultLayout path="/ticket" component={Ticket} Private={true}/>
                <LoginLayout path="/forgetPassword" component={ForgetPassword} Private={false}/>
                <LoginLayout path="/resetPassword/:code" component={ResetPassword} Private={false}/>
                <LoginLayout path="/activation/:code" component={Activation} Private={false}/>
                <DefaultLayout path="/profile" component={Profile} Private={true}/>
                <DefaultLayout path="/withdrawal" component={Withdrawal} Private={true}/>
                <DefaultLayout path="/help" component={Help}/>
                <DefaultLayout path="/history" component={History}/>
                <GameLayout path="/game/:provider/:name/:id/:type" component={GameView} Private={true}/>
                <GameLayout path="/game" component={Game}/>
                <GameLayout path="/luckyWheel/:id(\d+)" component={LuckyWheelView}/>
                <DefaultLayout path="/contact" component={Contact}/>
                <Route path="/maintenance" component={Maintenance}/>
                <DefaultLayout path="*" component={NotFound}/>
            </Switch>
        </Suspense>
    </BrowserRouter>
);
export default Routes;
