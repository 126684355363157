import {
    SET_INIT,
    INITIAL_AMOUNT,
    INCREASE_AMOUNT,
    DECREASE_AMOUNT,
    GET_USER_DATA,
    SET_USERNAME,
    SET_IN_PLAY_COUNT,
    ADD_BULK_FAVORITE_MATCH,
    ADD_FAVORITE_MATCH,
    DELETE_FAVORITE_MATCH,
    ADD_BET,
    SET_BET,
    UPDATE_BET,
    DELETE_BET,
    DELETE_ALL_BET,
    DELETE_ALL_BET_EXCEPT,
    TOGGLE_LOCK_BET,
    SET_BET_TYPE,
    SET_MODAL,
    SET_VARS,
    SET_VERIFICATION,
    DELETE_VERIFICATION,
    SET_LANGUAGE,
    SET_STREAM,
} from "../constants/action-types";
import Storage from "../../services/storage";
import Api from "../../services/Api";

export function setInit(payload) {
    return { type: SET_INIT, payload };
}
export function initialAmount(payload) {
    return { type: INITIAL_AMOUNT, payload };
}
export function increaseAmount(payload) {
    return { type: INCREASE_AMOUNT, payload };
}
export function decreaseAmount(payload) {
    return { type: DECREASE_AMOUNT, payload };
}
export function setUserName(payload) {
    return { type: SET_USERNAME, payload };
}
export function setInPlayCount(payload) {
    return { type: SET_IN_PLAY_COUNT, payload };
}
export function addBulkFavoriteMatch(payload) {
    return { type: ADD_BULK_FAVORITE_MATCH, payload};
}
export function addFavoriteMatch(payload) {
    return { type: ADD_FAVORITE_MATCH, payload};
}
export function deleteFavoriteMatch(payload) {
    return { type: DELETE_FAVORITE_MATCH, payload};
}
export function toggleLockBet() {
    return { type: TOGGLE_LOCK_BET};
}
export function addBet(payload) {
    return { type: ADD_BET, payload};
}
export function setBet(payload) {
    return { type: SET_BET, payload };
}
export function updateBet(payload) {
    return { type: UPDATE_BET, payload };
}
export function deleteBet(payload) {
    return { type: DELETE_BET, payload };
}
export function deleteAllBet(payload) {
    return { type: DELETE_ALL_BET, payload};
}
export function deleteAllBetsExcept(payload) {
    return { type: DELETE_ALL_BET_EXCEPT, payload};
}
export function setBetType(payload) {
    return { type: SET_BET_TYPE, payload};
}
export function setModal(payload) {
    return { type: SET_MODAL, payload};
}
export function setVars(payload) {
    return { type: SET_VARS, payload};
}
export function setVerification(payload) {
    return { type: SET_VERIFICATION, payload};
}
export function deleteVerification(payload) {
    return { type: DELETE_VERIFICATION, payload};
}
export function setLanguage(payload) {
    return { type: SET_LANGUAGE, payload};
}
export function getUserData(payload) {
    return { type: GET_USER_DATA, payload};
}
export function setStream(payload) {
    return { type: SET_STREAM, payload};
}
export function init(isUser = false) {
    return function(dispatch) {
        return Api({
            method: 'get',
            url: "default/init" + (isUser ? '-user' : ''),
        }).then((response) => {
            const data = response.data.data;
            dispatch({ type: SET_VARS, payload: data });
            if (isUser) {
                dispatch({ type: GET_USER_DATA, payload: data.user });
                dispatch({ type: INITIAL_AMOUNT, payload: data.amount });
                if (!data.user.status) {
                    Storage.removeRefreshToken();
                    Storage.removeToken();
                    window.location = '/';
                }
            }
            //dispatch({ type: SET_IN_PLAY_COUNT, payload: response.data.items.inPlayCount });

            return true;
        }).catch(error => {
            return false;
        });
    };
}
