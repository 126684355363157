import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import Helper from "../components/General/Helper";

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    .use(HttpApi)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        detection: {
            caches:[],
        },
        debug: false,
        fallbackLng: 'en',
        //supportedLngs: ['en', 'fa'],
        preload: false,//['en', 'fa'],// Default: false. array of languages to preload. Important on server-side to assert translations are loaded before rendering views.
        load: 'languageOnly',
        backend: {
            loadPath: Helper.API_URL() + 'default/translate?lang={{lng}}',
            queryStringParams: { v: '1.0' },
            crossDomain: false,
            //allowMultiLoading: false
        },
        react: {
            //wait: true
            useSuspense: false
        },
/*        resources: {
            en: {
                translation: en,
            },
        }*/
    }, (err, t) => {
        if (err) return console.log("i18n: " + err)
    });

export default i18n;
