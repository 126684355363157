class Helper {
    static cache = {};
    static injectScript(id, src, onLoad = () => {}, onError = () => {}, attr = null) {
        /* Remove script which already loaded */
        if (document.getElementById(id) !== null) {
            document.body.removeChild(document.getElementById(id));
        }

        if (!document.getElementById(id)) {
            const script = document.createElement("script");
            if (attr !== null) {
                Object.keys(attr).map(key => script.setAttribute(key, attr[key]));
            }
            script.src = src;
            script.id = id;
            script.onload = onLoad;
            script.onerror = onError;

            if (document.body) {
                document.body.appendChild(script);
            }
        }
    }
    static injectInternalScript(id, content, onLoad = () => {}) {
        /* Remove script which already loaded */
        if (document.getElementById(id) !== null) {
            document.body.removeChild(document.getElementById(id));
        }

        if (!document.getElementById(id)) {
            const script = document.createElement("script");
            script.id = id;
            script.onload = onLoad;
            script.innerText = content;

            if (document.body) {
                document.body.appendChild(script);
            }
        }
    }
    static checkJsExists = (url) => {
        const scripts = document.getElementsByTagName('script');
        for (let i = scripts.length; i--;) {
            if (scripts[i].src === url) return true;
        }
        return false;
    }
    static checkJsIdExists = (id) => {
        const scripts = document.getElementsByTagName('script');
        for (let i = scripts.length; i--;) {
            if (scripts[i].id === id) return true;
        }
        return false;
    }
    static removeJsById = id => {
        const script = document.getElementById(id);
        script.parentNode.removeChild(script);
    };
    static pricePattern(fractionDigits) {
        //return fractionDigits > 0 ? /^\.?(?!-)\d+(?:\.\d{0,2})?$/ : /^[0-9]+$/;
        return fractionDigits > 0 ? "[0-9]*.?[0-9]{0," + fractionDigits + "}" : "[0-9]*";
    }
    static floatify(number, format = true, fractionDigits = 2) {
        /*const whole = Math.floor(number);// 1
        const fraction = this.sub(number, whole, 4);// .25
        if (round) {
            number = whole + Math.round(fraction);
        }*/
        number = parseFloat((number).toFixed(fractionDigits));
        if (format) {
            number = this.numberFormat(number);
        }
        return number;
    }
    static add(a, b, precision) {
        const x = Math.pow(10, precision || 2);
        return (Math.round(a * x) - Math.round(b * x)) / x;
    }
    static sub(a, b, precision) {
        const x = Math.pow(10, precision || 2);
        return (Math.round(a * x) - Math.round(b * x)) / x;
    }
    static DOMAIN() {
        if (process.env.REACT_APP_ENV === 'DEV') {
            return process.env.REACT_APP_DOMAIN;
        }
        let domain = window.location.hostname;
        if (domain.indexOf('www.') > -1) {
            domain = domain.substring(4);
        }
        return domain;
    }
    static API_URL() {
        // return  'https://api.xxx/v1/'
        return 'https://api.' + this.DOMAIN() + '/v2/';
    }
    static BASE_URL() {
        return 'https://' + this.DOMAIN();
    }
    static BASE_PATH() {
        return this.BASE_URL() + '/';
    }
    static SERVER_PATH() {
        return 'https://content.' + this.DOMAIN() + '/statics/';
    }
    static numberFormat(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    static currencySymbol(currency) {
        if (currency === null) {
            return 'تومان';
        }
        const symbols = {
            'IRT': 'تومان',
            'USD': '$',
            'EUR': '€',
        };
        if (!(currency in symbols)) {
            return '';
        }
        return symbols[currency];
    }
    static currencyLabel(currency) {
        if (currency === null) {
            return 'تومان';
        }
        const symbols = {
            'IRT': 'تومان',
            'USD': 'دلار',
            'EUR': 'یورو',
        };
        if (!(currency in symbols)) {
            return '';
        }
        return symbols[currency];
    }
    static getParams() {
        let params = {};
        let parser = document.createElement('a');
        parser.href = window.location;
        let query = parser.search.substring(1);
        let vars = query.split('&');
        for (let i = 0; i < vars.length; i++) {
            if (vars[i] !== '') {
                let pair = vars[i].split('=');
                params[pair[0]] = decodeURIComponent(pair[1]);
            }
        }
        return params;
    }
    static randomNumberBetween = (min, max) => { // min and max included
        return Math.floor(Math.random() * (max - min + 1) + min)
    };
    static translateOptionName = (name, game) => {
        if (!name) {
            return;
        }
        let cacheKey = name + (game && (game.id || ''));

        if (this.cache[cacheKey] === undefined) {
            this.cache[cacheKey] = name;
            const teamMap = {
                "Team 1": "home",
                "Player 1": "home",
                "team 1": "home",
                "Team 2": "away",
                "Player 2": "away",
                "team 2": "away",
                "W1": "home",
                "W2": "away",
                "Home": "home",
                "Away": "away",
            };
            const teamExactMap = {
                //'1' : 'home',
                ' 1': 'home',
                '1 ': 'home',
                //'2' : 'away',
                '2 ': 'away',
                ' 2': 'away',
            };

            if (this.cache[cacheKey] in teamExactMap) {
                this.cache[cacheKey] = game[teamExactMap[this.cache[cacheKey]]];
            } else {
                // eslint-disable-next-line array-callback-return
                Object.keys(teamMap).map(term => {
                    let fieldName = teamMap[term];
                    if (game && game[fieldName]) {
                        while ((game[fieldName].lastIndexOf(term) === -1) && (this.cache[cacheKey] !== this.cache[cacheKey].replace(term, game[fieldName] + ' '))) {
                            this.cache[cacheKey] = this.cache[cacheKey].replace(term, game[fieldName] + ' ');
                        }
                    }
                });
            }
        }

        return this.cache[cacheKey];
    };
    static languageList = () => {
        return {
            'en': {name: 'English', nativeName: 'English', key: 'en', code: 'en-US', dir: 'ltr'},
            'fa': {name: 'Persian', nativeName: 'فارسی', key: 'fa', code: 'fa-IR', dir: 'rtl'},
            /*tr: {nativeName: 'Türkçe'},
            az: {nativeName: 'Azərbaycan'},
            ru: {nativeName: 'Русский'},*/
        };
    };
}

export default Helper;
