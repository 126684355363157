export const SET_INIT = "SET_INIT";
export const INITIAL_AMOUNT = "INITIAL_AMOUNT";
export const INCREASE_AMOUNT = "INCREASE_AMOUNT";
export const DECREASE_AMOUNT = "DECREASE_AMOUNT";
export const GET_USER_DATA = "GET_USER_DATA";
export const SET_USERNAME = "SET_USERNAME";
export const SET_IN_PLAY_COUNT = "SET_IN_PLAY_COUNT";
export const ADD_BULK_FAVORITE_MATCH = "ADD_BULK_FAVORITE_MATCH";
export const ADD_FAVORITE_MATCH = "ADD_FAVORITE_MATCH";
export const DELETE_FAVORITE_MATCH = "DELETE_FAVORITE_MATCH";
export const TOGGLE_LOCK_BET = "TOGGLE_LOCK_BET";
export const ADD_BET = "ADD_BET";
export const SET_BET = "SET_BET";
export const UPDATE_BET = "UPDATE_BET";
export const DELETE_BET = "DELETE_BET";
export const DELETE_ALL_BET = "DELETE_ALL_BET";
export const DELETE_ALL_BET_EXCEPT = "DELETE_ALL_BET_EXCEPT";
export const SET_BET_TYPE = "SET_BET_TYPE";
export const SET_MODAL = "SET_MODAL";
export const SET_VARS = "SET_VARS";
export const SET_VERIFICATION = "SET_VERIFICATION";
export const DELETE_VERIFICATION = "DELETE_VERIFICATION";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_STREAM = "SET_STREAM";
