import React, {Component} from 'react';
import Routes from './routes';
import {ToastContainer, toast} from 'react-toastify';
import {withTranslation} from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import Storage from "./services/storage";
import {getUserData, init, initialAmount, setInit, setLanguage, setVars} from "./redux/actions";
import {connect} from "react-redux";
import Helper from "./components/General/Helper";
import Api from "./services/Api";

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loadSite: false,
        };
    }

    async componentDidMount() {
        const {i18n} = this.props;
        let langObj;
        let languageKeys = [];
        const queryParams = new URLSearchParams(document.location.search)
        const languages = Helper.languageList();
        let language = queryParams.get("lang")
        const isLogin = Storage.isLogin();

        if (language) {
            langObj = languages[language];

            /* If we don't support language in query params we set English language */
            if (langObj.length === 0) {
                languageKeys = Object.keys(languages);
                langObj = languages[languageKeys[0]];
            }
            Storage.setData('i18nextLng', langObj.code);

        } else if (Storage.getLanguage() != null) {// Read from storage
            /* Read language from storage */
            language = Storage.getLanguage().substring(0, 2);
            langObj = language in languages ? languages[language] : [];
            if (langObj.length === 0) {
                languageKeys = Object.keys(languages);
                langObj = languages[languageKeys[0]];
            }
        }

        await Api({
            method: 'get',
            url: "default/init" + (isLogin ? '-user' : ''),
            headers: {
                'accept-language': (langObj !== undefined ? langObj.code : null),
            },
        }).then((response) => {
            const data = response.data.data;
            this.props.setVars(data);
            if (isLogin) {
                this.props.getUserData(data.user);
                this.props.initialAmount(data.amount);
                if (!data.user.status) {
                    Storage.removeRefreshToken();
                    Storage.removeToken();
                    window.location = '/';
                }
            }

            this.setState({loadSite: true})

            if (language == null) {// Default language
                let defaultLangInBackend = data.defaultLanguage;
                langObj = languages[defaultLangInBackend];
                Storage.setData('i18nextLng', langObj.code);
            }

            /* Save language in redux */
            this.props.setLanguage(langObj.code);

            i18n.changeLanguage(langObj.code, () => {
                document.documentElement.lang = langObj.key;
                document.documentElement.dir = langObj.dir;//i18n.dir();
            });
        }).catch(error => {
            console.log(error);
        });
    }

    render() {
        return (
            <div className="App maxHeight">
                {
                    this.state.loadSite === true ?
                        <React.Fragment>
                            <ToastContainer position={toast.POSITION.TOP_CENTER}/>
                            <Routes/>
                        </React.Fragment>
                        :
                        null
                }
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setLanguage: (item) => dispatch(setLanguage(item)),
        init: (isUser) => dispatch(init(isUser)),
        setInit: (value) => dispatch(setInit(value)),
        setVars: (value) => dispatch(setVars(value)),
        getUserData: (value) => dispatch(getUserData(value)),
        initialAmount: (value) => dispatch(initialAmount(value)),
    };
}


export default connect(
    null,
    mapDispatchToProps,
)(withTranslation()(App));
